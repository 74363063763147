export enum NotificationType {
  CAMERA_SHARE = "camera_share",
  CAMERA_STATUS_CHANGE = "camera_status_changed",
  MENTION = "mention",
  TIMELAPSE_CREATED = "timelapse_created",
  TIMELAPSE_FAILED = "timelapse_failed",
  DRONE_PROCESSING = "drone_processing_done",
  PROCESSING_360 = "three_sixty_done",
}

export interface CameraShareContext {
  cameraExid: string
  projectExid: string
  shareId: number
}

//   export interface CameraStatusChangeContext {
//     cameraExid: string;
//     projectExid: string;
//     status: string;
//   }

//   export interface MentionContext {
//     postId: number;
//     commentId?: number;
//   }

export type Notification = {
  id: number
  type: NotificationType
  insertedAt: string
  initiatorId: number
  initiatorFullname: string
  initiatorEmail: string
  targetId: number
  targetFullname: string
  targetEmail: string
  readTimestamp: string | null
  context: CameraShareContext //| CameraStatusChangeContext | MentionContext
}
