import type { CameraExid } from "./camera"
import type { ProjectExid } from "./project"

export enum TimelapseReportType {
  safety = "safety",
  construction = "construction",
  siteActivity = "siteActivity",
}

export enum TimelapseType {
  hour = "hour",
  day = "day",
  week = "week",
  month = "month",
  year = "year",
  fullProject = "full_project",
}

export enum TimelapseReportSeverity {
  low = "low",
  medium = "medium",
  high = "high",
}

export enum TimelapseActivityType {
  workers = "workers",
  equipment = "equipment",
  vehicles = "vehicles",
}

export type TimelapseReport = {
  id: number
  cameraExid: CameraExid
  projectExid: ProjectExid
  timelapseId: number
  timelapseType: TimelapseType
  type: TimelapseReportType
  fromDate: string
  toDate: string
  title: string
  region: string | null
  description: string | null
  level: TimelapseReportSeverity | null
  activityType: TimelapseActivityType | null
  activityCount: number | null
  insertedAt: string
  updatedAt: string
}

export type TimelapseReportFilters = {
  projectExid: ProjectExid
  cameraExid?: CameraExid
  fromDate: string
  toDate: string
  activityType?: TimelapseActivityType
  severity?: TimelapseReportSeverity
  timelapseType: TimelapseType
  reportType: TimelapseReportType
  confidence?: number
}

export type TimelineEventCount = {
  date: string
  counts: Record<string, number>
}
