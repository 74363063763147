import { Camera, CameraExid } from "@evercam/shared/types/camera"
import type { Exid } from "./index"

type DateType = Date | string | number

export type MediaExid = Exid

export type Media = {
  cameraExid?: CameraExid
  cameraName: string
  cameraTimezone: string
  createdAt: string
  fromDate: string
  id: number
  requesterEmail: string
  requesterName: string
  title: string
  toDate: string
  url?: string
  exid?: MediaExid
  embedCode?: string
  fileName?: string
  format?: string
  frames?: number
  link?: string
  public?: boolean
  type: MediaType
  status?: MediaStatus
  thumbnailUrl?: string
  mediaUrl?: string
}

export enum MediaType {
  EditedImage = "edit",
  ExternalUrl = "url",
  Clip = "clip",
  File = "file",
  LocalClip = "local_clip",
  XRay = "x-ray",
  Timelapse = "timelapse",
  Compare = "compare",
}

export enum MediaFileType {
  GIF = "gif",
  MP4 = "mp4",
  PNG = "png",
  PDF = "pdf",
  JPEG = "jpeg",
}

export enum MediaStatus {
  Pending = "Pending",
  Creating = "Creating",
  Processing = "Processing",
  Extracting = "Extracting",
  Uploading = "Uploading",
  Completed = "Completed",
  Failed = "Failed",
  NoData = "No Data",
}

export enum MediaStatusId {
  Pending = 0,
  Processing = 1,
  Completed = 2,
  Failed = 3,
}

export type MediaFilterQueryParams = {
  sort?: string
  sortBy?: string
  dateRange?: string | string[]
  fromDate?: string
  toDate?: string
  cameraName?: string
  requesterEmail?: string
  type?: MediaType
  cameraExid?: string
  page?: number
  limit?: number
  camera?: Pick<Camera, "name">
}

export type MediaUpdateRequestPayload = {
  name?: string
  title?: string
  public?: boolean
}

export type MediaCreateRequestPayload = {
  fromDate: DateType
  toDate: DateType
  title: string
  type: MediaType
  fileUrl?: string
  fileExtension?: string
  url?: string
  content?: string
  cameraExid?: string
  requestedBy?: string
}

export type MediaUsersResponsePayload = Array<{
  id: number
  email: string
  name: string
}>
