import { Media } from "@evercam/shared/types/media"
import { Camera } from "@evercam/shared/types/camera"

export enum VideoWallItemType {
  liveView = "live",
  video = "video",
  image = "image",
}

export type VideoWallItem<T extends Camera | Media> = {
  value: T
  type: VideoWallItemType
}

export type VideoWallPresetItemConfig = {
  camera: Camera
  h: number
  i: string | number
  moved: boolean
  w: number
  x: number
  y: number
}

export type VideoWallPreset = {
  name?: string
  configuration: Array<VideoWallPresetItemConfig>
}
